<template>
	<v-layout v-if="favorite" fill-height align-center justify-center>
		<v-flex>
			<v-icon medium dark color="yellow" class="badge-format badge-icon">star</v-icon>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'FavoriteBadge',
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		favorite: function () {
			return this.model ? this.model.favorite : false
		}
	}
}
</script>
<style scoped>
.badge-format {
	width: 20px;
	height: 20px;
	aspect-ratio: 1;
	font-size: 80%;
}
.badge-icon {
	font-size: 120%;
}
</style>
